<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <div class="p-d-flex p-justify-between p-mt-2">
          <BaseDatePicker v-model="date" @dateChange="loadAllEngineers" />
          <Button @click="setWorkDays">Сохранить</Button>
        </div>

        <DataTable
          :value="allEngineers"
          v-model:filters="filters"
          class="p-datatable-customers p-datatable-striped attendance-log p-mt-4"
          data-key="id"
          filter-display="row"
          :loading="isLoading"
          row-hover
        >
          <template #empty>Инженеры не найдены</template>

          <Column field="lastname" header="Фамилия">
            <template #body="slotProps">
              <router-link
                :to="{
                  name: 'UserDetail',
                  params: { id: slotProps.data.userId },
                }"
              >
                {{ slotProps.data.lastname }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                class="p-column-filter"
              />
            </template>
          </Column>
          <Column field="firstname" header="Имя">
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                class="p-column-filter"
              />
            </template>
          </Column>
          <Column field="engineerLevel" header="Класс">
            <template #body="slotProps">
              {{ getEngineerLevelLabel(slotProps.data.engineerLevel) }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                v-model="filterModel.value"
                @change="filterCallback"
                class="p-column-filter"
                :options="ENGINEER_LEVEL_LIST"
                option-label="label"
                option-value="value"
              />
            </template>
          </Column>
          <Column field="department" header="Филиал">
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback"
                class="p-column-filter"
              />
            </template>
          </Column>
          <Column
            field="engineerAtWork"
            header="На рабочем месте"
            header-class="p-d-flex p-justify-end"
          >
            <template #body="slotProps">
              <div class="p-d-flex p-justify-end p-align-center">
                <Checkbox
                  :value="slotProps.data.userId"
                  v-model="engineersAtWork"
                />
              </div>
            </template>
            <template #filter>
              <div class="p-d-flex p-justify-end">
                <Button @click="selectAllEngineers" class="select-all-button">
                  Выделить всех
                </Button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { getEngineerLevelLabel } from "@/models/engineerLevel";
import { FilterMatchMode } from "primevue/api";
import { ENGINEER_LEVEL_LIST } from "@/models/engineerLevel";
import BaseDatePicker from "@/components/BaseDatePicker";
import moment from "moment";

export default {
  name: "AttendanceLog",
  components: {
    BaseDatePicker,
  },
  data() {
    return {
      allEngineers: [],
      engineersAtWork: [],
      date: new Date(),

      isLoading: false,

      filters: {
        firstname: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        lastname: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        engineerLevel: {
          value: null,
          matchMode: FilterMatchMode.IN,
        },
        department: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },

      getEngineerLevelLabel,
      ENGINEER_LEVEL_LIST,
    };
  },
  mounted() {
    this.loadAllEngineers();
  },
  methods: {
    async loadAllEngineers(date = this.date) {
      this.isLoading = true;

      this.allEngineers = (
        await this.$store.dispatch("myprofiles/getAttendanceLog", date)
      ).workDays;

      this.engineersAtWork = [];

      this.allEngineers.forEach(
        (engineer) =>
          engineer.worked && this.engineersAtWork.push(engineer.userId)
      );
      this.allEngineers.sort((a, b) => a.lastname.localeCompare(b.lastname))

      this.isLoading = false;
    },

    selectAllEngineers() {
      if (this.engineersAtWork.length < this.allEngineers.length) {
        this.engineersAtWork = this.allEngineers.map(
          (engineer) => engineer.userId
        );
        return;
      }

      this.engineersAtWork = [];
    },

    async setWorkDays() {
      const data = this.allEngineers.map((engineer) => ({
        day: moment(this.date).format("YYYY-MM-DD"),
        worked: this.engineersAtWork.includes(engineer.userId),
        userId: engineer.userId,
      }));

      this.$store.dispatch("myprofiles/setWorkDays", data);

      this.$toast.add({
        severity: "success",
        summary: "Сохранено",
        detail: "Данные сохранены",
        life: 6000,
      });
    },
  },
};
</script>
<style lang="scss">
.attendance-log {
  .select-all-button {
    width: auto;
  }

  .p-link {
    display: none;
  }
}
</style>
