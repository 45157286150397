<template>
  <DatePicker v-model="value">
    <template v-slot="{ inputValue, inputEvents }">
      <InputText :value="inputValue" v-on="inputEvents" />
    </template>
  </DatePicker>
</template>

<script>
import { Calendar, DatePicker } from "v-calendar";

export default {
  name: "BaseDatePicker",
  props: {
    date: Date,
  },
  components: {
    Calendar,
    DatePicker,
  },
  computed: {
    value: {
      get() {
        return this.date;
      },
      set(newValue) {
        this.$emit("dateChange", newValue);
      },
    },
  },
};
</script>
